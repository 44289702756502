import React, {useMemo} from 'react'
import {ColorValue, StyleProp, ViewStyle} from 'react-native'
import {Circle, G, Path, Rect} from 'react-native-svg'

import {useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {SdlSVG, useThemeIconColor} from './ProfileIcons'

interface ModalIconsProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
  color?: string
}

export const DownArrowIcon = (props: ModalIconsProps) => {
  const color = useThemeIconColor('#666666', '#ffffff')
  return (
    <SdlSVG
      defaultSize={16}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        d="M7.32018 10.3623L4.79672 7.72464C4.48938 7.40338 4.42079 7.0358 4.59097 6.62188C4.76114 6.20797 5.06428 6.00068 5.50038 6H10.4988C10.9355 6 11.239 6.20729 11.4092 6.62188C11.5793 7.03647 11.5104 7.40406 11.2024 7.72464L8.67897 10.3623C8.58191 10.4638 8.47677 10.5399 8.36354 10.5906C8.2503 10.6413 8.12898 10.6667 7.99958 10.6667C7.87017 10.6667 7.74885 10.6413 7.63562 10.5906C7.52238 10.5399 7.41724 10.4638 7.32018 10.3623Z"
        fill={color}
      />
    </SdlSVG>
  )
}

export const QuotationIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={72}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        opacity={0.1}
        d="M33.0324 61.9808H-0.0078125V36.54C-0.0078125 27.1709 1.93809 19.7797 5.83789 14.3584C9.7377 8.93709 16.4242 4.15642 25.8895 0.000366211L33.0324 13.5176C27.2107 16.2482 23.1828 18.9709 20.9566 21.6855C18.7305 24.3922 17.4893 27.5953 17.233 31.2949H33.0324V61.9808ZM71.542 61.9808H38.5018V36.54C38.5018 27.1709 40.4477 19.7797 44.3475 14.3584C48.2473 8.93709 54.9338 4.15642 64.399 0.000366211L71.542 13.5176C65.7203 16.2482 61.6924 18.9709 59.4662 21.6855C57.24 24.3922 55.9988 27.5953 55.7426 31.2949H71.542V61.9808Z"
        fill="black"
      />
    </SdlSVG>
  )
}

export const ShareLikeIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={20}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Path
        d="M6.99219 6.9423C6.99219 6.60896 7.09219 6.28396 7.27552 6.00896L9.55052 2.62563C9.90885 2.08396 10.8005 1.70063 11.5589 1.98396C12.3755 2.25896 12.9172 3.17563 12.7422 3.9923L12.3089 6.7173C12.2755 6.9673 12.3422 7.1923 12.4839 7.3673C12.6255 7.52563 12.8339 7.62563 13.0589 7.62563H16.4839C17.1422 7.62563 17.7089 7.8923 18.0422 8.35896C18.3589 8.80896 18.4172 9.3923 18.2089 9.98396L16.1589 16.2256C15.9005 17.259 14.7755 18.1006 13.6589 18.1006H10.4089C9.85052 18.1006 9.06719 17.909 8.70885 17.5506L7.64219 16.7256C7.23385 16.4173 6.99219 15.9256 6.99219 15.409V6.9423Z"
        fill="#292D32"
      />
      <Path
        d="M3.48366 5.3158C2.19199 5.3158 1.66699 5.8158 1.66699 7.04913V15.4325C1.66699 16.6658 2.19199 17.1658 3.48366 17.1658H4.34199C5.63366 17.1658 6.15866 16.6658 6.15866 15.4325V7.04913C6.15866 5.8158 5.63366 5.3158 4.34199 5.3158H3.48366Z"
        fill="#292D32"
      />
    </SdlSVG>
  )
}

export const ShareRepostIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={20}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66699 9.99996C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99996C18.3337 5.39996 14.6003 1.66663 10.0003 1.66663C5.40033 1.66663 1.66699 5.39996 1.66699 9.99996ZM14.192 13.4833L12.7837 14.8916C12.6587 15.0166 12.5003 15.075 12.342 15.075C12.1837 15.075 12.0253 15.0166 11.9003 14.8916C11.6587 14.65 11.6587 14.25 11.9003 14.0083L12.242 13.6666H7.58366C6.50033 13.6666 5.62533 12.7833 5.62533 11.7083V10.2333C5.62533 9.89163 5.90866 9.60829 6.25033 9.60829C6.59199 9.60829 6.87533 9.89163 6.87533 10.2333V11.7083C6.87533 12.1 7.19199 12.4166 7.58366 12.4166H12.242L11.9003 12.075C11.6587 11.8333 11.6587 11.4333 11.9003 11.1916C12.142 10.95 12.542 10.95 12.7837 11.1916L14.192 12.6C14.2503 12.6583 14.292 12.725 14.3253 12.8C14.392 12.9583 14.392 13.1333 14.3253 13.2833C14.292 13.3583 14.2503 13.425 14.192 13.4833ZM13.7503 10.3916C13.4087 10.3916 13.1253 10.1083 13.1253 9.76663V8.29163C13.1253 7.89996 12.8087 7.58329 12.417 7.58329H7.75866L8.10033 7.91663C8.34199 8.15829 8.34199 8.55829 8.10033 8.79996C7.97533 8.92496 7.81699 8.98329 7.65866 8.98329C7.50033 8.98329 7.34199 8.92496 7.21699 8.79996L5.80866 7.39163C5.75033 7.33329 5.70866 7.26663 5.67533 7.19163C5.60866 7.04163 5.60866 6.86663 5.67533 6.71663C5.70866 6.64163 5.75033 6.56663 5.80866 6.50829L7.21699 5.09996C7.45866 4.85829 7.85866 4.85829 8.10033 5.09996C8.34199 5.34163 8.34199 5.74163 8.10033 5.98329L7.75866 6.32496H12.417C13.5003 6.32496 14.3753 7.20829 14.3753 8.28329V9.76663C14.3753 10.1083 14.092 10.3916 13.7503 10.3916Z"
        fill="#292D32"
      />
    </SdlSVG>
  )
}

export const RadioIcon = (props: ModalIconsProps) => {
  const {active} = props

  if (active) {
    return (
      <SdlSVG
        defaultSize={18}
        size={props.size}
        style={[{transform: [{translateY: 0}]}, props.style]}>
        <Rect x="0.5" y="1" width="17" height="17" rx="8.5" stroke="#0ABF52" />
        <Rect
          x="5.25"
          y="5.75"
          width="7.5"
          height="7.5"
          rx="3.75"
          fill="#0ABF52"
        />
      </SdlSVG>
    )
  }
  return (
    <SdlSVG
      defaultSize={18}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Rect opacity="0.01" x="0" y="0.5" width="18" height="18" fill="black" />
      <Rect
        x="0.5"
        y="1"
        width="17"
        height="17"
        rx="8.5"
        stroke="#999999"
        fill="none"
      />
    </SdlSVG>
  )
}

export const ShareMessageIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={20}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65866 5C5.44199 5 5.23366 5.00833 5.03366 5.03333C2.79199 5.225 1.66699 6.55 1.66699 8.99167V12.325C1.66699 15.6583 3.00033 16.3167 5.65866 16.3167H5.99199C6.17533 16.3167 6.41699 16.4417 6.52533 16.5833L7.52533 17.9167C7.96699 18.5083 8.68366 18.5083 9.12533 17.9167L10.1253 16.5833C10.2503 16.4167 10.4503 16.3167 10.6587 16.3167H10.992C13.4337 16.3167 14.7587 15.2 14.9503 12.95C14.9753 12.75 14.9837 12.5417 14.9837 12.325V8.99167C14.9837 6.33333 13.6503 5 10.992 5H5.65866ZM4.58366 10.8333C4.58366 10.375 4.95866 10 5.41699 10C5.87533 10 6.25033 10.375 6.25033 10.8333C6.25033 11.2917 5.87533 11.6667 5.41699 11.6667C4.95033 11.6667 4.58366 11.2917 4.58366 10.8333ZM7.49199 10.8333C7.49199 10.375 7.86699 10 8.32533 10C8.78366 10 9.15866 10.375 9.15866 10.8333C9.15866 11.2917 8.79199 11.6667 8.32533 11.6667C7.85866 11.6667 7.49199 11.2917 7.49199 10.8333ZM10.4087 10.8333C10.4087 10.375 10.7837 10 11.242 10C11.7003 10 12.0753 10.375 12.0753 10.8333C12.0753 11.2917 11.7003 11.6667 11.242 11.6667C10.7753 11.6667 10.4087 11.2917 10.4087 10.8333Z"
        fill="#292D32"
      />
      <Path
        d="M18.3172 8.99163C18.3172 10.6583 17.8005 11.7916 16.7672 12.4166C16.5172 12.5666 16.2255 12.3666 16.2255 12.075L16.2339 8.99163C16.2339 5.65829 14.3255 3.74996 10.9922 3.74996L5.9172 3.75829C5.62553 3.75829 5.42553 3.46663 5.57553 3.21663C6.20053 2.18329 7.33386 1.66663 8.9922 1.66663H14.3255C16.9839 1.66663 18.3172 2.99996 18.3172 5.65829V8.99163Z"
        fill="#292D32"
      />
    </SdlSVG>
  )
}

export const ShareLinkIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG defaultSize={49} size={48} {...props}>
      <Circle cx={24} cy={24} r={24} fill="#E7E7E7" />
      <Path
        fill="#000"
        fillRule="evenodd"
        d="M24.713 14.307a.75.75 0 0 0-.463.693v4.023c-5.667.742-10 5.844-10 11.97V32a.75.75 0 0 0 1.433.31c1.278-2.816 4.548-4.969 8.567-5.209V31a.75.75 0 0 0 1.28.53l8-8a.75.75 0 0 0 0-1.06l-8-8a.75.75 0 0 0-.817-.163Zm1.037 12.024v-.002a.75.75 0 0 0-.75-.75c-3.698 0-7.033 1.458-9.131 3.77.712-4.871 4.54-8.593 9.18-8.899a.75.75 0 0 0 .701-.748v-2.891L31.94 23l-6.19 6.19V26.33Z"
        clipRule="evenodd"
      />
    </SdlSVG>
  )
}

export const DownLoadIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={49}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Circle cx="24" cy="24.415" r="24" fill="#E7E7E7" />
      <Path
        d="M28.9405 20.8999C32.5405 21.2099 34.0105 23.0599 34.0105 27.1099V27.2399C34.0105 31.7099 32.2205 33.4999 27.7505 33.4999H21.2405C16.7705 33.4999 14.9805 31.7099 14.9805 27.2399V27.1099C14.9805 23.0899 16.4305 21.2399 19.9705 20.9099"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.5 14V26.88"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M27.8504 24.6499L24.5004 27.9999L21.1504 24.6499"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SdlSVG>
  )
}

export const StartIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={props.size}
      style={[{transform: [{translateY: 3}]}, props.style]}>
      <Path
        d="M3.57662 1.704C4.21012 0.568 4.52662 0 5.00012 0C5.47362 0 5.79012 0.568 6.42362 1.704L6.58762 1.998C6.76762 2.321 6.85762 2.4825 6.99762 2.589C7.13762 2.6955 7.31262 2.735 7.66262 2.814L7.98062 2.886C9.21062 3.1645 9.82512 3.3035 9.97162 3.774C10.1176 4.244 9.69862 4.7345 8.86012 5.715L8.64312 5.9685C8.40512 6.247 8.28562 6.3865 8.23212 6.5585C8.17862 6.731 8.19662 6.917 8.23262 7.2885L8.26562 7.627C8.39212 8.9355 8.45562 9.5895 8.07262 9.88C7.68962 10.1705 7.11362 9.9055 5.96262 9.3755L5.66412 9.2385C5.33712 9.0875 5.17362 9.0125 5.00012 9.0125C4.82662 9.0125 4.66312 9.0875 4.33612 9.2385L4.03812 9.3755C2.88662 9.9055 2.31062 10.1705 1.92812 9.8805C1.54462 9.5895 1.60812 8.9355 1.73462 7.627L1.76762 7.289C1.80362 6.917 1.82162 6.731 1.76762 6.559C1.71462 6.3865 1.59512 6.247 1.35712 5.969L1.14012 5.715C0.301618 4.735 -0.117383 4.2445 0.0286175 3.774C0.174617 3.3035 0.790118 3.164 2.02012 2.886L2.33812 2.814C2.68762 2.735 2.86212 2.6955 3.00262 2.589C3.14312 2.4825 3.23262 2.321 3.41262 1.998L3.57662 1.704Z"
        fill="#FFC01F"
      />
    </SdlSVG>
  )
}

export const RightIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={props.size}
      style={[{transform: [{translateY: 3}]}, props.style]}>
      <Path
        d="M4 9.5L7.5 6L4 2.5"
        stroke={props?.color ? props?.color : '#333333'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SdlSVG>
  )
}

export const ImageIcon = (props: ModalIconsProps) => {
  const theme = useThemeName()
  const t = useTheme()
  const colors = useMemo(() => {
    return {
      one: t.atoms.modalBg2.backgroundColor, // ?? theme === 'light' ? 'white' : '#000000',
      two: theme === 'light' ? '#979797' : '#ffffff',
      three: theme === 'light' ? 'black' : '#ffffff',
    }
  }, [theme, t.atoms.modalBg2.backgroundColor])
  return (
    <SdlSVG
      defaultSize={31}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Rect
        width="31"
        height="31"
        rx="9"
        fill={props?.active ? t.palette.primary : colors.one}
      />
      <Rect
        x="0.5"
        y="0.5"
        width="30"
        height="30"
        rx="8.5"
        stroke={colors.two}
        strokeOpacity="0.2"
      />
      <Path
        d="M8 20.5V10.6667C8 9.73333 8 9.26667 8.18167 8.91C8.34167 8.59583 8.59583 8.34167 8.91 8.18167C9.26667 8 9.73333 8 10.6667 8H20.3333C21.2667 8 21.7333 8 22.09 8.18167C22.4036 8.34145 22.6585 8.59641 22.8183 8.91C23 9.26583 23 9.7325 23 10.6642V20.3358C23 20.74 23 21.0567 22.985 21.3142M8 20.5C8 21.3233 8.01083 21.755 8.18167 22.0892C8.34167 22.4033 8.59583 22.6583 8.91 22.8183C9.26583 23 9.7325 23 10.6642 23H20.3367C21.2683 23 21.7342 23 22.09 22.8183C22.4033 22.6583 22.6583 22.4033 22.8183 22.0892C22.9208 21.8875 22.9658 21.6508 22.985 21.3142M8 20.5L11.9733 15.865L11.9742 15.8633C12.3267 15.4525 12.5033 15.2467 12.7125 15.1717C12.8963 15.1066 13.0971 15.1081 13.28 15.1758C13.4883 15.2533 13.6633 15.4617 14.01 15.8783L16.2358 18.5492C16.5575 18.9358 16.7192 19.1292 16.9158 19.2075C17.089 19.2756 17.28 19.2838 17.4583 19.2308C17.6617 19.1708 17.8417 18.9917 18.1992 18.6342L18.6133 18.22C18.9775 17.855 19.16 17.6733 19.3667 17.6133C19.5479 17.5612 19.7415 17.572 19.9158 17.6442C20.1142 17.7267 20.275 17.9275 20.5975 18.3292L22.985 21.3142M22.985 21.3142L23 21.3333M18 13C17.779 13 17.567 12.9122 17.4107 12.7559C17.2545 12.5996 17.1667 12.3877 17.1667 12.1667C17.1667 11.9457 17.2545 11.7337 17.4107 11.5774C17.567 11.4211 17.779 11.3333 18 11.3333C18.221 11.3333 18.433 11.4211 18.5893 11.5774C18.7455 11.7337 18.8333 11.9457 18.8333 12.1667C18.8333 12.3877 18.7455 12.5996 18.5893 12.7559C18.433 12.9122 18.221 13 18 13Z"
        stroke={colors.three}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SdlSVG>
  )
}

export const CompleteIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={18}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Rect width={18} height={18} rx={8} fill="#0ABF52" />
      <Path
        d="M4.125 9L7.125 12L13.125 6"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </SdlSVG>
  )
}

export const CloseShareIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M2 12c0 5.51 4.49 10 10 10s10-4.49 10-10S17.51 2 12 2 2 6.49 2 12Zm13.36 3.36c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-2.3-2.3-2.3 2.3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l2.3-2.3-2.3-2.3a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 2.3-2.3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.3 2.3 2.3 2.3c.29.29.29.77 0 1.06Z"
        clipRule="evenodd"
      />
    </SdlSVG>
  )
}

export const CloseIcon = (
  props: ModalIconsProps & {circleColor?: ColorValue | undefined},
) => {
  return (
    <SdlSVG
      defaultSize={12}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Circle
        opacity="0.3"
        cx="6"
        cy="6"
        r="6"
        transform="rotate(90 6 6)"
        fill={props.circleColor || 'white'}
      />
      <Path
        d="M6.19 6.31967L7.9561 8.08577C8.0452 8.17182 8.18682 8.17059 8.2744 8.083C8.36199 7.99542 8.36322 7.8538 8.27717 7.7647L6.5113 5.9986L8.27717 4.2325C8.33456 4.17515 8.357 4.09153 8.33603 4.01315C8.31505 3.93477 8.25386 3.87353 8.17549 3.8525C8.09713 3.83147 8.0135 3.85385 7.9561 3.9112L6.19 5.67753L4.4239 3.9112C4.36651 3.85381 4.28285 3.8314 4.20445 3.8524C4.12605 3.87341 4.06481 3.93465 4.0438 4.01305C4.0228 4.09145 4.04521 4.17511 4.10261 4.2325L5.86893 5.9986L4.10261 7.7647C4.01394 7.85342 4.014 7.99722 4.10272 8.08588C4.19144 8.17454 4.33524 8.17449 4.4239 8.08577L6.19 6.31989V6.31967Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </SdlSVG>
  )
}

export const CloseGrayIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={12}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Circle
        opacity={0.3}
        cx={6}
        cy={6}
        r={6}
        transform="rotate(90 6 6)"
        fill="black"
      />
      <Path
        d="M6.1922 6.32162L7.9583 8.08772C8.04739 8.17377 8.18901 8.17254 8.2766 8.08496C8.36419 7.99737 8.36542 7.85575 8.27937 7.76665L6.51349 6.00055L8.27937 4.23445C8.33676 4.1771 8.3592 4.09348 8.33822 4.0151C8.31725 3.93672 8.25606 3.87548 8.17769 3.85445C8.09932 3.83343 8.01569 3.8558 7.9583 3.91316L6.1922 5.67948L4.4261 3.91316C4.3687 3.85576 4.28505 3.83335 4.20665 3.85436C4.12825 3.87536 4.06701 3.9366 4.046 4.015C4.02499 4.09341 4.04741 4.17706 4.1048 4.23445L5.87113 6.00055L4.1048 7.76665C4.01614 7.85538 4.01619 7.99917 4.10492 8.08783C4.19364 8.1765 4.33744 8.17644 4.4261 8.08772L6.1922 6.32185V6.32162Z"
        fill="white"
        stroke="white"
        strokeWidth={0.5}
      />
    </SdlSVG>
  )
}

export const QuoteIcon = (props: ModalIconsProps) => {
  const primaryColor = props?.color
  const color = useThemeIconColor('#000000', '#ffffff')
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 0}]}, props.style]}>
      <Path
        d="M20.3279 7.48176C19.1199 6.27392 17.5147 5.60933 15.8088 5.60933H9.10325V4.32694C9.10325 3.91797 8.82276 3.76452 8.47665 3.98663L5.3904 5.97884C5.04573 6.20228 5.0486 6.56016 5.39776 6.77625L8.47075 8.67941C8.81698 8.89563 9.10325 8.73637 9.10325 8.32743V7.0495H15.8102C18.5385 7.0495 20.7587 9.27104 20.7587 11.9979C20.7587 12.4507 20.6988 12.8977 20.579 13.327C20.4724 13.7111 20.6974 14.107 21.0815 14.2121C21.1458 14.2326 21.21 14.2413 21.2743 14.2413C21.5897 14.2413 21.879 14.031 21.9681 13.7141C22.1214 13.1576 22.1974 12.5806 22.1974 11.9994C22.1974 10.2935 21.5328 8.6868 20.3279 7.48176ZM19.1374 17.2224L16.0658 15.3192C15.7167 15.1031 15.4304 15.2608 15.4304 15.6712V16.9464H8.19182C5.46345 16.9464 3.24337 14.7263 3.24337 11.998C3.24337 11.0427 3.5165 10.1153 4.03213 9.3163C4.24832 8.98178 4.1518 8.53491 3.81884 8.31874C3.48431 8.10402 3.03745 8.20041 2.82272 8.53335C2.15525 9.56602 1.80176 10.7652 1.80176 11.9994C1.80176 13.7068 2.46635 15.3106 3.67273 16.5169C4.87923 17.7249 6.48442 18.3894 8.19182 18.3894H15.4319V19.6719C15.4319 20.0822 15.7138 20.2356 16.0585 20.0122L19.1448 18.0185C19.4894 17.7964 19.4866 17.4386 19.1374 17.2224Z"
        fill={primaryColor || color}
      />
    </SdlSVG>
  )
}

export const TargetIcon = (props: ModalIconsProps) => {
  const theme = useThemeName()
  const t = useTheme()
  const colors = useMemo(() => {
    return {
      one: t.atoms.modalBg2.backgroundColor, //theme === 'light' ? 'white' : '#000000',
      two: theme === 'light' ? '#979797' : '#ffffff',
      three: theme === 'light' ? 'black' : '#ffffff',
    }
  }, [theme, t.atoms.modalBg2.backgroundColor])
  return (
    <SdlSVG
      defaultSize={32}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Rect
        width="32"
        height="32"
        rx="9"
        fill={props?.active ? t.palette.primary : colors.one}
      />
      <Rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="8.5"
        stroke={colors.two}
        strokeOpacity="0.2"
      />
      <G ClipPath="url(#clip0_3283_111262)">
        <Path
          d="M16 9.24962C14.6071 9.24991 13.2484 9.6811 12.1102 10.4841C10.9721 11.287 10.1102 12.4224 9.64275 13.7345C9.17534 15.0467 9.1253 16.4713 9.4995 17.813C9.87369 19.1547 10.6538 20.3477 11.7328 21.2286C12.8118 22.1094 14.1369 22.6349 15.5264 22.7329C16.9158 22.8309 18.3016 22.4967 19.4936 21.776C20.6856 21.0554 21.6254 19.9837 22.1843 18.7078C22.7431 17.4319 22.8936 16.0144 22.615 14.6496C22.5934 14.5523 22.5914 14.4517 22.6091 14.3537C22.6268 14.2556 22.6639 14.162 22.7182 14.0785C22.7724 13.9949 22.8428 13.9229 22.9252 13.8668C23.0075 13.8108 23.1002 13.7716 23.1979 13.7518C23.2955 13.7319 23.3962 13.7317 23.4939 13.7511C23.5916 13.7705 23.6845 13.8092 23.7671 13.865C23.8498 13.9207 23.9205 13.9923 23.9751 14.0757C24.0297 14.159 24.0672 14.2524 24.0854 14.3504C24.4397 16.0878 24.2247 17.8932 23.4723 19.4989C22.7199 21.1046 21.4702 22.4252 19.9084 23.2648C18.3466 24.1045 16.5558 24.4185 14.8014 24.1604C13.0471 23.9024 11.4226 23.0859 10.1687 21.832C8.91485 20.5782 8.09837 18.9537 7.84029 17.1993C7.58221 15.445 7.89627 13.6542 8.73592 12.0923C9.57557 10.5305 10.8961 9.28079 12.5018 8.52842C14.1075 7.77605 15.9129 7.56108 17.6504 7.91537C17.75 7.93178 17.8453 7.96812 17.9305 8.02223C18.0158 8.07633 18.0892 8.14708 18.1465 8.23025C18.2038 8.31341 18.2436 8.40728 18.2638 8.50622C18.2839 8.60516 18.2838 8.70715 18.2636 8.80607C18.2434 8.905 18.2034 8.99882 18.1461 9.08193C18.0887 9.16504 18.0152 9.23571 17.9299 9.28973C17.8446 9.34375 17.7493 9.38 17.6496 9.3963C17.55 9.41261 17.4481 9.40863 17.35 9.38462C16.9056 9.29463 16.4534 9.2494 16 9.24962Z"
          fill={colors.three}
        />
        <Path
          d="M16 12.9992C15.4066 12.9992 14.8265 13.1752 14.3331 13.5049C13.8397 13.8346 13.4551 14.3031 13.228 14.8514C13.0009 15.3996 12.9415 16.0029 13.0573 16.5849C13.173 17.1669 13.4588 17.7016 13.8784 18.1212C14.298 18.5408 14.8326 18.8265 15.4147 18.9423C15.9967 19.0581 16.5999 18.9987 17.1482 18.7716C17.6964 18.5445 18.165 18.1599 18.4947 17.6665C18.8244 17.1731 19.0004 16.593 19.0004 15.9996C18.9965 15.8987 19.013 15.798 19.0489 15.7036C19.0848 15.6093 19.1394 15.5231 19.2094 15.4503C19.2794 15.3775 19.3634 15.3196 19.4563 15.2801C19.5493 15.2405 19.6492 15.2202 19.7502 15.2202C19.8512 15.2202 19.9511 15.2405 20.044 15.2801C20.137 15.3196 20.2209 15.3775 20.291 15.4503C20.361 15.5231 20.4156 15.6093 20.4515 15.7036C20.4874 15.798 20.5039 15.8987 20.5 15.9996C20.5 16.8896 20.2361 17.7596 19.7416 18.4997C19.2471 19.2397 18.5443 19.8164 17.7221 20.157C16.8998 20.4976 15.995 20.5868 15.1221 20.4131C14.2492 20.2395 13.4474 19.8109 12.818 19.1816C12.1887 18.5522 11.7601 17.7504 11.5865 16.8775C11.4128 16.0046 11.5019 15.0998 11.8425 14.2775C12.1831 13.4552 12.7599 12.7524 13.4999 12.258C14.24 11.7635 15.11 11.4996 16 11.4996C16.1009 11.4957 16.2016 11.5122 16.296 11.5481C16.3903 11.584 16.4765 11.6386 16.5493 11.7086C16.6221 11.7786 16.6799 11.8626 16.7195 11.9555C16.759 12.0485 16.7794 12.1484 16.7794 12.2494C16.7794 12.3504 16.759 12.4503 16.7195 12.5433C16.6799 12.6362 16.6221 12.7202 16.5493 12.7902C16.4765 12.8602 16.3903 12.9148 16.296 12.9507C16.2016 12.9866 16.1009 13.0031 16 12.9992Z"
          fill={colors.three}
        />
        <Path
          d="M21.229 7.7651C21.6227 7.82698 21.9884 8.10485 22.1065 8.55823L22.3832 9.61685L23.4407 9.8936C23.8952 10.0117 24.172 10.3774 24.2361 10.7711C24.2957 11.1491 24.1619 11.5327 23.8874 11.8061L23.0256 12.669C22.0739 13.6207 20.8994 13.7006 19.6787 13.3822L16.5299 16.5311C16.4613 16.6054 16.3784 16.6651 16.2862 16.7066C16.194 16.748 16.0944 16.7705 15.9933 16.7725C15.8922 16.7745 15.7917 16.756 15.6979 16.7182C15.6042 16.6804 15.519 16.6241 15.4475 16.5525C15.3761 16.481 15.3198 16.3958 15.2821 16.302C15.2444 16.2081 15.2261 16.1077 15.2282 16.0066C15.2303 15.9055 15.2528 15.8059 15.2944 15.7137C15.336 15.6215 15.3957 15.5387 15.4701 15.4702L18.6179 12.3214C18.2995 11.0996 18.3794 9.92623 19.3311 8.97448L20.1929 8.11273C20.4674 7.83823 20.851 7.70435 21.229 7.76398V7.7651Z"
          fill={colors.three}
        />
      </G>
    </SdlSVG>
  )
}

export const TagIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={14}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02467 0.942798C6.29384 0.918307 6.56518 0.948628 6.8223 1.03193C7.07942 1.11523 7.317 1.24979 7.52067 1.42746L7.62067 1.5208L12.8353 6.73613C13.1942 7.09508 13.4032 7.57705 13.4199 8.08436C13.4366 8.59166 13.2598 9.08634 12.9253 9.46813L12.8353 9.56413L9.064 13.3355C8.70505 13.6943 8.22308 13.9033 7.71577 13.92C7.20847 13.9367 6.71379 13.7599 6.332 13.4255L6.236 13.3355L1.02 8.12013C0.828984 7.92913 0.678641 7.70141 0.57804 7.45071C0.47744 7.20001 0.428668 6.93153 0.434667 6.66146L0.442 6.5248L0.756667 3.06813C0.797065 2.62265 0.985634 2.20358 1.29224 1.87788C1.59884 1.55218 2.00577 1.33867 2.448 1.27146L2.56733 1.25746L6.02467 0.942798ZM4.34933 4.85013C4.2255 4.97397 4.12726 5.12098 4.06024 5.28278C3.99322 5.44458 3.95873 5.618 3.95873 5.79313C3.95873 5.96826 3.99322 6.14168 4.06024 6.30348C4.12726 6.46528 4.2255 6.6123 4.34933 6.73613C4.47317 6.85997 4.62019 6.9582 4.78199 7.02522C4.94379 7.09224 5.1172 7.12674 5.29233 7.12674C5.46746 7.12674 5.64088 7.09224 5.80268 7.02522C5.96448 6.9582 6.1115 6.85997 6.23533 6.73613C6.48543 6.48603 6.62594 6.14682 6.62594 5.79313C6.62594 5.43944 6.48543 5.10023 6.23533 4.85013C5.98523 4.60003 5.64603 4.45953 5.29233 4.45953C4.93864 4.45953 4.59943 4.60003 4.34933 4.85013Z"
        fill={props?.color ?? '#5E6272'}
      />
    </SdlSVG>
  )
}

export const FriendsIcon = (props: ModalIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        d="M2.83333 4.99967C2.83333 6.71301 4.17333 8.09967 5.92 8.15967C5.97333 8.15301 6.02667 8.15301 6.06667 8.15967C6.08 8.15967 6.08667 8.15967 6.1 8.15967C6.10667 8.15967 6.10667 8.15967 6.11333 8.15967C7.82 8.09967 9.16 6.71301 9.16667 4.99967C9.16667 3.25301 7.74667 1.83301 6 1.83301C4.25333 1.83301 2.83333 3.25301 2.83333 4.99967Z"
        fill={props?.color ?? '#5E6272'}
      />
      <Path
        d="M2.62063 9.93293C1.77396 10.4996 1.30729 11.2663 1.30729 12.0863C1.30729 12.9063 1.77396 13.6663 2.61396 14.2263C3.54729 14.8529 4.77396 15.1663 6.00063 15.1663C7.22729 15.1663 8.45396 14.8529 9.38729 14.2263C10.2273 13.6596 10.694 12.8996 10.694 12.0729C10.6873 11.2529 10.2273 10.4929 9.38729 9.93293C7.52729 8.69293 4.49396 8.69293 2.62063 9.93293Z"
        fill={props?.color ?? '#5E6272'}
      />
      <Path
        d="M11.2396 7.9721C11.2329 7.9721 11.2329 7.9721 11.2263 7.9721H11.2062C11.1662 7.9721 11.1262 7.9721 11.0929 7.98543C10.4462 8.01876 9.85292 7.8121 9.40625 7.4321C10.0929 6.81876 10.4863 5.89876 10.4062 4.89876C10.3596 4.35876 10.1729 3.86543 9.89292 3.44543C10.1463 3.31876 10.4396 3.23876 10.7396 3.2121C12.0463 3.09876 13.2129 4.0721 13.3263 5.3921C13.4329 6.68543 12.5129 7.81876 11.2396 7.9721Z"
        fill={props?.color ?? '#5E6272'}
      />
      <Path
        d="M13.4989 13.1466C12.8322 13.5133 11.9922 13.6866 11.1589 13.6666C11.6389 13.2333 11.9189 12.6933 11.9722 12.1199C12.0389 11.2933 11.6455 10.4999 10.8589 9.86661C10.4122 9.51327 9.89219 9.23327 9.32552 9.02661C10.7989 8.59994 12.6522 8.88661 13.7922 9.80661C14.4055 10.2999 14.7189 10.9199 14.6589 11.5599C14.6055 12.2066 14.1922 12.7666 13.4989 13.1466Z"
        fill={props?.color ?? '#5E6272'}
      />
    </SdlSVG>
  )
}
