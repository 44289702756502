import React, {useEffect, useRef, useState} from 'react'
import {View} from 'react-native'
import {BskyAgent} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {isDev} from '#/lib/constants'
// import * as EmailValidator from 'email-validator'
import {logEvent} from '#/lib/statsig/statsig'
import {
  validateHandle,
  // validateInviteCode
} from '#/lib/strings/handles'
import {logger} from '#/logger'
// import {Text as UtilText} from '#/view/com/util/text/Text'
// import {useAgent} from '#/state/session'
// import {SendingNetworkAgent} from '#/state/session/sdn-agent'
import {ScreenTransition} from '#/screens/Login/ScreenTransition'
import {
  is13,
  // is18,
  useSignupContext,
  // useSubmitSignup,
} from '#/screens/Signup/state'
// import {Policies} from '#/screens/Signup/StepInfo/Policies'
import {atoms as a, useTheme} from '#/alf'
// import * as DateField from '#/components/forms/DateField'
import {FormError} from '#/components/forms/FormError'
import {HostingProvider} from '#/components/forms/HostingProvider'
import * as TextField from '#/components/forms/TextField'
import {At_Stroke2_Corner0_Rounded as At} from '#/components/icons/At'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
// import {Envelope_Stroke2_Corner0_Rounded as Envelope} from '#/components/icons/Envelope'
// import {Lock_Stroke2_Corner0_Rounded as Lock} from '#/components/icons/Lock'
import {TimesLarge_Stroke2_Corner0_Rounded as Times} from '#/components/icons/Times'
import {Text} from '#/components/Typography'
import {BackNextButtons} from '../BackNextButtons'

// function sanitizeDate(date: Date): Date {
//   if (!date || date.toString() === 'Invalid Date') {
//     logger.error(`Create account: handled invalid date for birthDate`, {
//       hasDate: !!date,
//     })
//     return new Date()
//   }
//   return date
// }

export function StepInfo({
  onPressBack,
  isServerError,
  refetchServer,
}: // isLoadingStarterPack,
{
  onPressBack: () => void
  isServerError: boolean
  refetchServer: () => void
  isLoadingStarterPack: boolean
}) {
  const [, setAddress] = useState('')
  const {_} = useLingui()
  // const agent = useAgent()
  const {state, dispatch} = useSignupContext()

  const t = useTheme()
  // const submit = useSubmitSignup({state, dispatch})
  const agent = new BskyAgent({service: state.serviceUrl})
  const handleValueRef = useRef<string>(state.handle)
  // const InviteCodeValueRef = useRef<string>(state.inviteCode)
  const [draftValue, setDraftValue] = React.useState(state.handle)
  // const [draftCode, setDraftCode] = React.useState(state.inviteCode)
  const validatedRef = React.useRef(false)

  useEffect(() => {
    state.wallet.getAddress().then(value => {
      setAddress(value.toLowerCase())
      dispatch({type: 'setAddress', value})
    })
  }, [dispatch, state.wallet])

  const onNextPress = React.useCallback(async () => {
    let pass = true
    let hasHandle = false
    dispatch({type: 'setIsLoading', value: true})
    if (!is13(state.dateOfBirth)) {
      pass = false
    }

    if (state.nickname && state.nickname.length > 20) {
      pass = false
      dispatch({
        type: 'setError',
        value: _(msg`Can be at most 20 characters long`),
      })
    }
    if (state.handle && state.handle.length > 20) {
      pass = false
      dispatch({
        type: 'setError',
        value: _(msg`Can be at most 20 characters long`),
      })
    }

    if (!state.handle) {
      pass = false
      dispatch({
        type: 'setError',
        value: _(msg`Please enter your user handle.`),
      })
    }

    // if (!state.inviteCode) {
    //   pass = false
    //   dispatch({
    //     type: 'setError',
    //     value: _(msg`Please enter your invite code`),
    //   })
    // }

    logEvent('signup:nextPressed', {
      activeStep: state.activeStep,
      phoneVerificationRequired:
        state.serviceDescription?.phoneVerificationRequired,
    })

    // if (state.serviceDescription?.inviteCodeRequired && !state.inviteCode) {
    //   pass = false
    //   dispatch({
    //     type: 'setError',
    //     value: _(msg`Please enter your invite code.`),
    //   })
    // }

    try {
      const uri = new URL(state.serviceUrl)
      const res = await agent.com.atproto.server.validateHandle({
        handle: state.handle + '.' + uri.hostname,
      })
      if (res.success && res.data.success) {
        hasHandle = true
      }
    } catch (error) {
      hasHandle = false
    }

    if (!hasHandle) {
      pass = false
      dispatch({
        type: 'setError',
        value: _(msg`This user handle is already taken.`),
      })
    }

    if (!pass) {
      dispatch({type: 'setIsLoading', value: false})
      return
    }

    try {
      dispatch({type: 'next'})
    } catch (error) {
      logger.log('login failed')
    } finally {
      dispatch({type: 'setIsLoading', value: false})
    }

    logEvent('signup:nextPressed', {
      activeStep: state.activeStep,
    })
  }, [
    _,
    agent.com.atproto.server,
    dispatch,
    state.activeStep,
    state.dateOfBirth,
    state.handle,
    state.nickname,
    state.serviceDescription?.phoneVerificationRequired,
    state.serviceUrl,
  ])

  const validCheck = validateHandle(draftValue, state.userDomain)
  // const validCodeCheck = validateInviteCode(draftCode)

  return (
    <>
      <View>
        <Text onPress={onPressBack}>
          <FontAwesomeIcon
            size={18}
            icon="angle-left"
            color={t.atoms.text.color}
            // style={[.text]}
          />
        </Text>
      </View>
      <Text style={[a.pb_3xl, a.text_3xl, a.font_bold, a.text_center]}>
        <Trans>Profile</Trans>
      </Text>
      <ScreenTransition>
        <View style={[a.gap_md]}>
          <FormError error={state.error} />
          {isDev && (
            <View>
              <TextField.LabelText>
                <Trans>Hosting provider</Trans>
              </TextField.LabelText>
              <HostingProvider
                serviceUrl={state.serviceUrl}
                onSelectServiceUrl={v =>
                  dispatch({type: 'setServiceUrl', value: v})
                }
              />
            </View>
          )}

          <>
            <View style={[a.gap_lg]}>
              <View>
                <TextField.LabelText>
                  <Trans>Nickname</Trans>
                </TextField.LabelText>
                <TextField.SubLabelText>
                  <Trans>This can be modified later in the setting page.</Trans>
                </TextField.SubLabelText>
                <TextField.Root>
                  {/* <TextField.Icon icon={At} /> */}
                  <TextField.Input
                    testID="handleInput"
                    onChangeText={val => {
                      if (state.error) {
                        dispatch({type: 'setError', value: ''})
                      }
                      dispatch({
                        type: 'setNickname',
                        value: val,
                      })
                    }}
                    // label={_(msg`Input your nickname`)}
                    label=""
                    value={state.nickname}
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoFocus
                    autoComplete="off"
                  />
                </TextField.Root>
              </View>
            </View>
            <View style={[a.gap_lg]}>
              <View>
                <TextField.LabelText>
                  <Trans>User Handle(@)</Trans>
                </TextField.LabelText>
                <TextField.SubLabelText>
                  <Trans>
                    You won’t be able to change it after it’s set up.
                  </Trans>
                </TextField.SubLabelText>
                <TextField.Root>
                  <TextField.Icon icon={At} />
                  <TextField.Input
                    testID="handleInput"
                    onChangeText={val => {
                      if (state.error) {
                        dispatch({type: 'setError', value: ''})
                      }

                      // These need to always be in sync.
                      handleValueRef.current = val
                      dispatch({
                        type: 'setHandle',
                        value: val,
                      })

                      setDraftValue(val)
                    }}
                    // label={_(msg`Input your user handle`)}
                    label=""
                    defaultValue={draftValue}
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoFocus
                    autoComplete="off"
                  />
                </TextField.Root>
              </View>

              {/* <View style={[a.gap_lg]}>
                <View>
                  <TextField.LabelText>
                    <Trans>Invite Code</Trans>
                  </TextField.LabelText>
                  <TextField.SubLabelText>
                    <Trans>
                      This can be modified later in the setting page.
                    </Trans>
                  </TextField.SubLabelText>
                  <TextField.Root>
                    <TextField.Input
                      testID="handleInput"
                      onChangeText={val => {
                        if (state.error) {
                          dispatch({type: 'setError', value: ''})
                        }
                        InviteCodeValueRef.current = val
                        dispatch({
                          type: 'setInviteCode',
                          value: val,
                        })
                        setDraftCode(val)
                      }}
                      // label={_(msg`Input your nickname`)}
                      label=""
                      value={state.inviteCode}
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoFocus
                      autoComplete="off"
                    />
                  </TextField.Root>
                </View>
              </View> */}

              {/* <View style={[a.align_center]}>
                  <TextField.LabelText>
                    <Trans>Wallet address</Trans>
                  </TextField.LabelText>
                  <View style={[a.w_full]}>
                    <Text
                      style={[
                        a.text_sm,
                        a.px_sm,
                        a.py_lg,
                        a.text_center,
                        a.rounded_md,
                        t.atoms.bg_contrast_25,
                      ]}>
                      <Trans>{address}</Trans>
                    </Text>
                  </View>
                </View> */}

              {/* {draftValue !== '' && (
                  <Text style={[a.text_md]}>
                    <Trans>Your full handle will be</Trans>{' '}
                    <Text style={[a.text_md, a.font_bold]}>
                      @{createFullHandle(draftValue, state.userDomain)}
                    </Text>
                  </Text>
                )} */}

              {draftValue !== '' && (
                <View
                  style={[
                    a.w_full,
                    a.rounded_sm,
                    a.border,
                    a.p_md,
                    a.gap_sm,
                    t.atoms.border_contrast_low,
                  ]}>
                  {state.error ? (
                    <View
                      style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                      <IsValidIcon valid={false} />
                      <Text style={[a.text_md, a.flex_1]}>{state.error}</Text>
                    </View>
                  ) : undefined}
                  {validCheck.hyphenStartOrEnd ? (
                    <View
                      style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                      <IsValidIcon valid={validCheck.handleChars} />
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>
                          Only supports letters, numbers, and hyphens.
                        </Trans>
                      </Text>
                    </View>
                  ) : (
                    <View
                      style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                      <IsValidIcon valid={validCheck.hyphenStartOrEnd} />
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>Doesn't begin or end with a hyphen</Trans>
                      </Text>
                    </View>
                  )}
                  <View
                    style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                    <IsValidIcon
                      valid={validCheck.frontLength && validCheck.totalLength}
                    />
                    {!validCheck.totalLength ? (
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>No longer than 20 characters</Trans>
                      </Text>
                    ) : (
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>Contains at least 3 characters.</Trans>
                      </Text>
                    )}
                  </View>

                  {/* <View
                    style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                    <IsValidIcon valid={validCodeCheck.inviteCodeLength} />
                    {!validCodeCheck.inviteCodeLength ? (
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>Invite Code No longer than 10 characters</Trans>
                      </Text>
                    ) : (
                      <Text style={[a.text_md, a.flex_1]}>
                        <Trans>Invite Code at least 3 characters.</Trans>
                      </Text>
                    )}
                  </View> */}
                </View>
              )}

              {/* <Policies
                  serviceDescription={state.serviceDescription}
                  needsGuardian={!is18(state.dateOfBirth)}
                  under13={!is13(state.dateOfBirth)}
                /> */}
            </View>
          </>
        </View>

        <BackNextButtons
          hideNext={!is13(state.dateOfBirth)}
          showRetry={isServerError}
          isLoading={state.isLoading}
          onBackPress={onPressBack}
          onNextPress={onNextPress}
          onRetryPress={refetchServer}
        />
      </ScreenTransition>
    </>
  )
}

function IsValidIcon({valid}: {valid: boolean}) {
  const t = useTheme()
  if (!valid) {
    return <Times size="md" style={{color: t.palette.negative_500}} />
  }
  return <Check size="md" style={{color: t.palette.positive_700}} />
}
